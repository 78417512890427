import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  Collapse,
  IconButton,
  Box,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  Tooltip,
} from '@mui/material';
import {
  useGetAllCompaniesControllerGetAllCompaniesQuery,
  useGetUsersListControllerGetUsersListQuery,
} from '@shared/services/apiService/apiService';
import { CreateCompanyPopup } from '@widgets/Popup/CreateCompany/CreateCompany';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowUp, ContentCopy } from '@mui/icons-material';
import { agentColumns, companyColumns, userColumns } from '../constants';
import { copyToClipboard, truncateId } from '../utils';
import i18n from '@shared/utils/i18n';

type Column = {
  id: string;
  label: string;
  isDate?: boolean;
  isTruncated?: boolean;
  isFullName?: boolean;
};

type Item = {
  [key: string]: any;
};

const renderCell = (item: Item, column: Column) => {
  if (column.isDate) {
    const date = item[column.id];
    return date ? new Date(date).toLocaleDateString() : '';
  }

  if (column.isTruncated) {
    return (
      <>
        {truncateId(item[column.id])}
        <Tooltip title={i18n.t('superAdminMainPage.companies.tooltips.copyId')}>
          <IconButton size="small" onClick={() => copyToClipboard(item[column.id])}>
            <ContentCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      </>
    );
  }
  if (column.isFullName) {
    return `${item.firstName} ${item.lastName}`;
  }
  return item[column.id] || '-';
};

export const CompaniesPage = () => {
  const [showDialogFlag, setShowDialogFlag] = useState(false);
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null);

  const { t } = useTranslation();

  const { data, refetch: refetchCompanies } = useGetAllCompaniesControllerGetAllCompaniesQuery();
  const { data: users } = useGetUsersListControllerGetUsersListQuery(
    { id: selectedCompanyId ?? '' },
    { skip: !selectedCompanyId },
  );

  const closeCreateCompanyDialog = () => {
    setShowDialogFlag(false);
    refetchCompanies();
  };

  const handleRowClick = (id: string) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: !prevOpen[id] }));
    setSelectedCompanyId(id);
  };

  return (
    <Box p={3}>
      <Button variant="contained" color="primary" onClick={() => setShowDialogFlag(true)}>
        {t('superAdminMainPage.companies.buttons.createCompany')}
      </Button>
      <CreateCompanyPopup showDialogFlag={showDialogFlag} closeDialog={closeCreateCompanyDialog} />
      <Box style={{ marginTop: '20px', overflow: 'auto' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                {companyColumns.map((column) => (
                  <TableCell key={column.id}>
                    <TableSortLabel>{column.label}</TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((company) => (
                <>
                  <TableRow key={company.id}>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleRowClick(company.id)}>
                        {open[company.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell>
                    {companyColumns.map((column) => (
                      <TableCell key={column.id}>{renderCell(company, column)}</TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={companyColumns.length + 1}>
                      <Collapse in={open[company.id]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <Card variant="outlined">
                                <CardContent>
                                  <Typography variant="h6" gutterBottom component="div">
                                    {t('superAdminMainPage.companies.cardTitles.agents')}
                                  </Typography>
                                  <Divider />
                                  <Table size="small" aria-label="agents">
                                    <TableHead>
                                      <TableRow>
                                        {agentColumns.map((column) => (
                                          <TableCell key={column.id}>{column.label}</TableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {company.agents?.map((agent: any) => (
                                        <TableRow key={agent.id}>
                                          {agentColumns.map((column) => (
                                            <TableCell key={column.id}>
                                              {renderCell(agent, column)}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item md={12}>
                              <Card variant="outlined">
                                <CardContent>
                                  <Typography variant="h6" gutterBottom component="div">
                                    {t('superAdminMainPage.companies.cardTitles.users')}
                                  </Typography>
                                  <Divider />
                                  <Table size="small" aria-label="users">
                                    <TableHead>
                                      <TableRow>
                                        {userColumns.map((column) => (
                                          <TableCell key={column.id}>{column.label}</TableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {users?.map((user) => (
                                        <TableRow key={user.id}>
                                          {userColumns.map((column) => (
                                            <TableCell key={column.id}>
                                              {renderCell(user, column)}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
